import {array} from "bfj/src/events";

function zodiac() {
    return [
        {"Id":"2023012502621685900","Name":"蛇","OpenNo":"12|24|36|48","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"},
        {"Id":"2023012508355010500","Name":"猴","OpenNo":"9|21|33|45","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"},
        {"Id":"2023012508517950600","Name":"鼠","OpenNo":"5|17|29|41","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"},
        {"Id":"2023012508529817200","Name":"牛","OpenNo":"4|16|28|40","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"},
        {"Id":"2023012510905289700","Name":"马","OpenNo":"11|23|35|47","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"},
        {"Id":"2023012512599886500","Name":"龙","OpenNo":"1|13|25|37|49","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"},
        {"Id":"2023012513053812200","Name":"兔","OpenNo":"2|14|26|38","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"},
        {"Id":"2023012513788227200","Name":"猪","OpenNo":"6|18|30|42","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"},
        {"Id":"2023012515712179800","Name":"羊","OpenNo":"10|22|34|46","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"},
        {"Id":"2023012515810627900","Name":"虎","OpenNo":"3|15|27|39","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"},
        {"Id":"2023012517973022900","Name":"鸡","OpenNo":"8|20|32|44","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"},
        {"Id":"2023012519608383100","Name":"狗","OpenNo":"7|19|31|43","CreateTime":"2023-01-25 00:00:00","IsEnable":"1"}
    ]
}

function getZodiac(num) {
    const zodiacList = zodiac();
    for (const item of zodiacList.values()) {
        const result = item.OpenNo.split('|');
        for (const res of result.values()) {
            if (res == num) {
                if (item.Name != undefined) {
                    return item.Name;
                }
            }
        }
    }
}

function getZodiacList(numberString) {
    let res = [];
    const number = numberString.split("|");
    number.forEach((value, index) => {
        res[index] = getZodiac(value);
    })
    return res;
}

export {
    zodiac,
    getZodiac,
    getZodiacList
};
