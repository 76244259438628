import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useMemo,
    Fragment,
} from "react";
import { observer } from 'mobx-react-lite'
import store from "../../../../stores";
import style from "./index.module.less";
const HashInstant = () => {

    const ResultFormatt = useMemo(() => {
        if (store.lotteryDataList && store.lotteryDataList[0]) {
            return store.lotteryDataList[0].Data.List;
        }
        return false;
    }, [store.lotteryDataList]);
    return (
        <div className={style.container}>
            <table className={style.tabel} cellSpacing="0">
                <tbody>
                <tr>
                    <td>期号</td>
                    <td>时间</td>
                    <td>号码</td>
                </tr>
                {ResultFormatt && ResultFormatt.length && ResultFormatt.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.LotNumber}</td>
                            <td>{item.LotResultTime}</td>
                            <td>{item.LotResultNo.replace(/\s*/g,"")}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
export default observer(HashInstant);