import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useMemo,
    Fragment,
  } from "react";
import { observer } from 'mobx-react-lite'
import store from '../../stores'
import style from "./index.module.less";

const TypeButton = () => {
    return (
        <div className={style.TypeButton}>
            <ul className={style.ulBox}>
                <li className={style.item+' '+(store.currentIndex==0?style.active:'')} onClick={()=>store.handlerClick(0)}>加拿大28</li>
                <li className={style.item+' '+(store.currentIndex==1?style.active:'')} onClick={()=>store.handlerClick(1)}>百家乐28</li>
                <li className={style.item+' '+(store.currentIndex==2?style.active:'')} onClick={()=>store.handlerClick(2)}>牛牛28</li>
                <li className={style.item+' '+(store.currentIndex==3?style.active:'')} onClick={()=>store.handlerClick(3)}>加拿大时时彩</li>
            </ul>
        </div>
    )
}
export default observer(TypeButton);