import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useMemo,
    Fragment,
  } from "react";
import { observer } from 'mobx-react-lite'
import store from "../../../../stores";
import style from "./index.module.less";
const Niuniu28 = () => {
    
    const ResultFormatt = useMemo(() => {
        if(store.niuniuData&&store.niuniuData.length>1) {
           return store.niuniuData[1].Data.List;
        }
        return false;
    }, [store.niuniuData]);

    const handlerColor = (name) => {
        if(!name)return;
        if(name=="牛一") return "#838488";
        if(name=="牛二"||name=="牛三"||name=="牛四"||name=="牛五") return "#15b3bc";
        if(name=="牛六"||name=="牛七"||name=="牛八"||name=="牛九") return "#116de9";
        if(name=="牛牛") return "#ff2b30";
    }
    return(
        <div className={style.container}>
            <table className={style.tabel} cellSpacing="0">
                <tbody>
                <tr>
                    <td>期号 </td>
                    <td>号码一</td>
                    <td>号码二</td>
                    <td>号码三</td>
                </tr>
                {ResultFormatt&&ResultFormatt.length&&ResultFormatt.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                {item.LotNumber}
                            </td>
                            <td>
                                <span style={{backgroundColor: handlerColor(item.LotResultOneNo)}}>
                                    {item.LotResultOneNo}
                                </span>
                            </td>
                            <td>
                                <span style={{backgroundColor: handlerColor(item.LotResultTwoNo)}}>
                                    {item.LotResultTwoNo}
                                </span>
                            </td>
                            <td>
                                <span style={{backgroundColor: handlerColor(item.LotResultThreeNo)}}>
                                    {item.LotResultThreeNo}
                                </span>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
export default observer(Niuniu28);