import http from "../utils/http";

/**
 * 获取波场哈希28开奖结果(最多返回100条数据)
 */
function GetTronHash28OpenResultList(params) {
  return new Promise((resolve, reject) => {
    http("get", "/Lottery/LotOpenResult/GetTronOpenResultList", params).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          console.log("网络异常~", error);
          reject(error);
        }
    );
  });
}

/**
 * 获取哈希PK10开奖结果(最多返回100条数据)
 */
function GetPk10HashOpenResultList(params) {
  return new Promise((resolve, reject) => {
    http("get", "/Lottery/LotOpenResult/GetPkOpenResultList", params).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          console.log("网络异常~", error);
          reject(error);
        }
    );
  });
}

/**
 * 获取波场哈希28开奖结果(最多返回100条数据)
 */
function GetHashInstantOpenResultList(params) {
  return new Promise((resolve, reject) => {
    http("get", "/Lottery/LotOpenResult/GetInstantOpenResultList", params).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          console.log("网络异常~", error);
          reject(error);
        }
    );
  });
}

/**
 * 获取加拿大28开奖结果(最多返回100条数据)
 */
function GetCanadaOpenResultList(params) {
  return new Promise((resolve, reject) => {
    http("get", "/Lottery/LotOpenResult/GetCanadaOpenResultList", params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}

/**
 * 获取牛牛28开奖结果(最多返回100条数据)
 */
function GetCowsOpenResultList(params) {
  return new Promise((resolve, reject) => {
    http("get", "/Lottery/LotOpenResult/GetCowsOpenResultList", params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}

/**
 * 获取百家乐28开奖结果(最多返回100条数据)
 */
function GetBaccaratOpenResultList(params) {
  return new Promise((resolve, reject) => {
    http("get", "/Lottery/LotOpenResult/GetBaccaratOpenResultList", params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}

/**
 * 获取澳洲幸运28开奖结果(最多返回100条数据)
 */
function GetFortunateOpenResultList(params) {
  return new Promise((resolve, reject) => {
    http("get", "/Lottery/LotOpenResult/GetFortunateOpenResultList", params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}

/**
 * 获取香港六合彩开奖结果(最多返回100条数据)
 */
function GetXGCOpenResultList(params) {
  return new Promise((resolve, reject) => {
    http("get", "/Lottery/LotOpenResult/GetXGCOpenResultList", params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}

/**
 * 加拿大28(开奖信息)
 */
function GetCanadaOpenResultInfo(params) {
  return new Promise((resolve, reject) => {
    http("get", "/BaseManage/LotteryResultInfo/GetCanadaOpenResultInfo", params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}

/**
 * 牛牛28(开奖信息)
 */
function GetCowsOpenResultInfo(params) {
  return new Promise((resolve, reject) => {
    http("get", "/BaseManage/LotteryResultInfo/GetCowsOpenResultInfo", params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}

/**
 * 百家乐28(开奖信息)
 */
function GetBaccaratOpenResultInfo(params) {
  return new Promise((resolve, reject) => {
    http("get", "/BaseManage/LotteryResultInfo/GetBaccaratOpenResultInfo", params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}

/**
 * 澳洲幸运28(开奖信息)
 */
function GetFortunateOpenResultInfo(params) {
  return new Promise((resolve, reject) => {
    http("get", "/BaseManage/LotteryResultInfo/GetFortunateOpenResultInfo", params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}

/**
 * 香港彩(开奖信息)
 */
function GetXGCOpenResultInfo(params) {
  return new Promise((resolve, reject) => {
    http("get", "/BaseManage/LotteryResultInfo/GetXGCOpenResultInfo", params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}

/**
 * 幸运10(开奖信息)
 */
function GetLotLuckyOpenResultList(params) {
  return new Promise((resolve, reject) => {
    http("get", "/Lottery/LotOpenResult/GetLotLuckyOpenResultList", params).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}

export {
  GetCanadaOpenResultList,
  GetCowsOpenResultList,
  GetBaccaratOpenResultList,
  GetFortunateOpenResultList,
  GetXGCOpenResultList,
  GetCanadaOpenResultInfo,
  GetCowsOpenResultInfo,
  GetBaccaratOpenResultInfo,
  GetFortunateOpenResultInfo,
  GetXGCOpenResultInfo,
  GetLotLuckyOpenResultList,
  GetTronHash28OpenResultList,
  GetHashInstantOpenResultList,
  GetPk10HashOpenResultList,
};
