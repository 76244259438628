import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useMemo,
    Fragment,
} from "react";
import { observer } from 'mobx-react-lite'
import store from "../../../../stores";
import style from "./index.module.less";
import moment from "moment";
const Xingyun10 = () => {

    const ResultFormatt = useMemo(() => {
        let arr = store.xingyunData ? store.xingyunData[0].Data.List : [];
        return arr;
    }, [store.xingyunData]);

    const formatTime = (val, format) => {
        return moment(val).format(format)
    }

    const formatResultNumber = (val) => {
        let arr = val || [];
        arr.map(v => {
            return
        })
        return
    }

    const TypeToName = (arr, type) => {
        let narr = []
        if (type == 1) {
            narr = arr
        } else if (type == 2) {
            narr = arr.map(v => { return Number(v) > 5 ? '大' : '小' })
        } else if (type == 3) {
            narr = arr.map(v => { return v % 2 == 0 ? '双' : '单' })
        }
        return narr
    }

    const TypeToClass = (v) => {
        let nClass = ''
        if (['小', '单'].includes(v)) {
            nClass = 'bg7f8ab0'
        }
        if (['大', '双'].includes(v)) {
            nClass = 'bgf9982e'
        }
        return nClass
    }

    return (
        <div className={style.container}>
            <table className={style.tabel} cellSpacing="0">
                <tbody>
                    <tr>
                        <td>时间</td>
                        <td>期数</td>
                        <td>中奖码</td>
                        <td>冠亚和</td>
                        <td>1-5龙虎</td>
                    </tr>
                    {ResultFormatt && ResultFormatt.length && ResultFormatt.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{formatTime(item.LotResultTime, 'YYYY-MM-DD')}<br />{formatTime(item.LotResultTime, 'HH:mm:ss')}</td>
                                <td>{item.LotNumber}</td>
                                <td>{TypeToName(item.LotResultNo.split(','), store.xingyunShowType).map((v, i) => {
                                    return (
                                        <span className={[style['span' + v], style[TypeToClass(v)]].join(' ')} key={i}>{v}</span>
                                    )
                                })}</td>
                                <td>
                                    <font>{item.SumFS}</font>
                                    <font className={item.BigStr == '小' ? style.C184dd5 : style.Cf12d35}>{item.BigStr}</font>
                                    <font className={item.SingleDoubleStr == '单' ? style.C184dd5 : style.Cf12d35}>{item.SingleDoubleStr}</font>
                                </td>
                                <td>{item.DragonTigerStr.split(',').map((v, i) => {
                                    return (
                                        <font className={v == '虎' ? style.C184dd5 : style.Cf12d35} key={i}>{v}</font>
                                    )
                                })}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
export default observer(Xingyun10);