// store
import { makeAutoObservable } from 'mobx'

const store = makeAutoObservable({
    currentIndex: 0,//当前选中的彩种
    currentTagIndex: 4, //当前选中的tag
    lotteryDataList: null,
    niuniuData: null,
    canadaData: null,
    aozhouData: null,
    pk10Data: null,
    baijialeData: null,
    hashInstantData: null,
    xingyunData: null,
    tronData: null,
    xingyunShowType: 1,
    handlerClick: (index) => {
        store.currentIndex = index;
        if (index !== 0) {
            store.currentTagIndex = 0;
        }
    },
    handlerTagClick: (index) => {
        if ((index == 1 || index == 2) && store.currentIndex > 0) {
            store.currentIndex = 0;
        }
        store.currentTagIndex = index;
    },
    setLotteryDataList: (data, type) => {
        if (type == 0) {
            store.canadaData = data;
        }
        if (type == 1) {
            // store.aozhouData = data;
            store.baijialeData = data;
        }
        if (type == 2) {
            store.niuniuData = data;
        }
        if (type == 3) {
            store.hashInstantData = data;
        }
        if (type == 4) {
            store.xingyunData = data;
        }
        if (type == 5) {
            store.pk10Data = data;
        }
        store.lotteryDataList = data;
    },
    changeXYShowType: (type) => {
        store.xingyunShowType = type
    }
})

export default store
