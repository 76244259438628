import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useMemo,
  Fragment,
} from "react";
import { observer } from 'mobx-react-lite'
import store from '../../stores'
import moment from "moment";
import { GetCanadaOpenResultList,GetCowsOpenResultList} from "../../api";
import style from "./index.module.less";

const Canada28Online = () => {
  const [data, setData] = useState(null);
  const [resData, setResData] = useState(null);
  const [diffTime, setDiffTime] = useState(0); // 时间差 /倒计时时间
  const [currentLotNumber, setCurrentLotNumber] = useState(0); //当前最新期号
  const [oddLotNumber, setOddLotNumber] = useState(0); //当前最旧期号
  const [currentSelectLotNumber, setCurrentSelectLotNumber] = useState(0); //当前选中期号
  const [showMipai, setShowMipai] = useState(false); //是否显示咪牌
  const [pointNumber, setPointNumber] = useState(1); //是否显示咪牌
  const [hasctx, setHasctx] = useState(null); //画布对象
  const timerRef = useRef(null);
  const onetimerRef = useRef(null);
  const clicktimerRef = useRef(null);
  const pointtimerRef = useRef(null);
  const canvasEl = useRef(null);
  const canvasPEl = useRef(null);
  const pointArr = ["", ".","..","..."];

  useEffect(() => {
    fetchData();
  }, []);

  //获取记录和下期开奖日期
  const fetchData = async () => {
    Promise.all([GetCanadaOpenResultList(),GetCowsOpenResultList()]).then(res=>{
      if(res && res[0].ErrorCode == 0 && res[0].Success) {
        store.setLotteryDataList(res,2);
        setResData(res);
        //如果正在开奖中，并且已经刮开牌，结果回来的一瞬间重新咪牌
        if(diffTime==0&&hasctx) {
          setHasctx(null);
          createCanvasMark();
        }
        setData(res[0].Data);
        //计算下一期剩余时间和最新期号
        const {
          NextDrawInfo: { CurrentTime, NextDrawTime },
          List,
        } = res[0].Data;
        setCurrentLotNumber(List[0].LotNumber);
        setOddLotNumber(List[List.length - 1].LotNumber);
        setCurrentSelectLotNumber(List[0].LotNumber);
        let nextTime = CurrentTime.split(" ")[0] + " " + NextDrawTime;
        let diffTimes = moment(nextTime).diff(moment(CurrentTime), "s");
        setDiffTime(diffTimes);
      }
    }).catch(err=>{
      clearTimeout(onetimerRef.current);
      if(store.currentIndex!==2) return;
      onetimerRef.current = setTimeout(() => {
        fetchData();
      }, 6000);
    })
  };

  useEffect(() => {
    // 开启定时器
    if (diffTime <= 0) {
      window.clearInterval(timerRef.current);
      window.clearInterval(onetimerRef.current);
      setDiffTime(0);
      onetimerRef.current = null;
      //判断是否开启咪牌模式
      if(showMipai) {
        handlerRefrsh();
      }
      clearTimeout(onetimerRef.current);
      onetimerRef.current = setTimeout(() => {
        fetchData();
      }, 6000);
      return;
    } else {
      timerRef.current = window.setInterval(() => {
        if (diffTime >= 1) {
          setDiffTime((diffTime) => diffTime - 1);
        }
      }, 1000);
    }
    return () => {
      clearInterval(timerRef.current);
      clearInterval(onetimerRef.current);
    };
  }, [diffTime]);

  useEffect(() => {
    // 清除副作用
    return () => {
      clearInterval(timerRef.current);
      clearTimeout(onetimerRef.current);
      clearTimeout(clicktimerRef.current);
      clearInterval(pointtimerRef.current);
    };
  }, []);

  //将秒数转换为分秒
  const converSecTommss = (diffTime) => {
    let currentSec = moment.duration(diffTime, "seconds");
    return moment({
      m: currentSec.minutes(),
      s: currentSec.seconds(),
    }).format("mm:ss");
  };

  //add
  const HandlingAddOrSubtraction = (type) => {
    clearTimeout(clicktimerRef.current);
    clicktimerRef.current = setTimeout(() => {
      if (type == "add") {
        currentSelectLotNumber < currentLotNumber &&
          setCurrentSelectLotNumber((pre) => pre + 1);
      } else {
        currentSelectLotNumber > oddLotNumber &&
          setCurrentSelectLotNumber((pre) => pre - 1);
      }
    }, 300);
  };

  //处理是否禁用
  const handlerIsDisabled = (type) => {
    if (type == "add") {
      return currentSelectLotNumber < currentLotNumber &&
        currentSelectLotNumber >= oddLotNumber
        ? false
        : true;
    } else {
      return currentSelectLotNumber <= currentLotNumber &&
        currentSelectLotNumber > oddLotNumber
        ? false
        : true;
    }
  };

  //处理开奖号码
  const ResultFormatt = useMemo(() => {
    if(store.niuniuData&&store.niuniuData.length>1) {
       return store.niuniuData[1].Data.List[0];
    }
    return false;
  }, [store.niuniuData]);

  
  //处理开奖号码
  const handlerOpenResultNumber = () => {
    // currentSelectLotNumber
    let arr = data&&data.List.filter((ietm)=>ietm.LotNumber==currentSelectLotNumber);
    arr = arr[0].LotResultNo.split('=');
    arr = data&&[...arr[0].split('+'),arr[1]];
    return arr;
  };

  useEffect(() => {
    if(diffTime<=0) {
      pointtimerRef.current = setInterval(()=>{
        if(pointNumber<3) {
          setPointNumber((pre)=>pre+1)
        }
        if(pointNumber==3) {
          setPointNumber((pre)=>pre-2)
        }
      },500)
    } else {
      setPointNumber(1)
      clearInterval(pointtimerRef.current);
    }
    return () => clearInterval(pointtimerRef.current)
  },[diffTime,pointNumber])

  // 绘制遮罩
  const createCanvasMark = () => {
    if(hasctx) return;
    var cvs = canvasEl.current;
    var ctx = cvs.getContext("2d");
    setHasctx(ctx);
    var p = canvasPEl.current;
    var px = p.offsetLeft-175+50;//-50
    var py = p.offsetTop-150+94;//-94
    ctx.beginPath(); //开始绘画
    ctx.fillStyle = "#484545"; //填充颜色
    ctx.fillRect(0, 0, 300, 105);//矩形左上角的 x 坐标。矩形左上角的 y 坐标。矩形的宽度，以像素计。矩形的高度，以像素计。
    ctx.closePath(); //关闭
    handlerMipai(cvs,px,py,p,ctx);
  }

  useEffect(()=>{
    if(canvasEl.current) {
      createCanvasMark();
    }
  })

  //处理咪牌函数
  const handlerMipai = (cvs,px,py,p,ctx) => {
    document.touchstart = null;
    document.touchmove = null;
    document.onmousedown = null;
    document.onmousemove = null;
    // 移动端
    cvs.ontouchstart = function () {
      document.ontouchmove = function (e) {
        var x = e.touches[0].clientX - cvs.offsetLeft; //获取鼠标位置
        var y = e.touches[0].clientY - cvs.offsetTop;
        console.log(p.offsetTop, y, e)
        ctx.clearRect(x - px + 20, y - py - 20, 40, 40);//在给定矩形内清空一个矩形
      }
      document.ontouchend = function () {
        document.touchstart = null;
        document.touchmove = null;
      }
    }

    // PC 端
    cvs.onmousedown = function () {
      document.onmousemove = function (e) {
        var x = e.clientX - cvs.offsetLeft; //获取鼠标位置
        var y = e.clientY - cvs.offsetTop;
        console.log(p.offsetTop, y, e)
        ctx.clearRect(x - px + 20, y - py - 20, 40, 40);//在给定矩形内清空一个矩形
      }
      document.onmouseup = function () {
        document.onmousedown = null;
        document.onmousemove = null;
      }
    }
  }

  //点击显示咪牌
  const handlerClickMipai = (isShow) => {
    setShowMipai(isShow);
    if(isShow==false) {
      setHasctx(null);
    }
  }

  const handlerRefrsh = () => {
    setHasctx(null);
    createCanvasMark();
  }

  const handlerColor = (name) => {
    if(!name)return;
    if(name=="牛一") return "#838488";
    if(name=="牛二"||name=="牛三"||name=="牛四"||name=="牛五") return "#15b3bc";
    if(name=="牛六"||name=="牛七"||name=="牛八"||name=="牛九") return "#116de9";
    if(name=="牛牛") return "#ff2b30";
}

  return (
    <div className={style.canada28Online}>
      {/* 最新期数 */}
      <div className={style.latestIssue}>
        {/* 图标 */}
        {/* <span className={style.img}></span> */}
        {/* 期数内容 */}
        <div className={style.content}>
          <div className={style.LotNumber}>
            最新：
            <span className={style.number}>
              {data && data.List[0].LotNumber}
            </span>
            期
          </div>
          <div className={style.PhaseController}>
            <button
              className={style.reduce}
              disabled={handlerIsDisabled("reducer")}
              onClick={() => HandlingAddOrSubtraction("reducer")}
            >
              -
            </button>
            <span>{currentSelectLotNumber}</span>
            <button
              className={style.add}
              disabled={handlerIsDisabled("add")}
              onClick={() => HandlingAddOrSubtraction("add")}
            >
              +
            </button>
          </div>
        </div>
      </div>

      {/* 开奖倒计时 */}
      {data && (
        <div className={style.countdown}>
          {/* 尚未开奖显示倒计时 */}
          {diffTime ? (
            <div className={style.ShowCountDown}>
              <span className={style.nextTetx}>下一期：</span>
              <div className={style.countDownContent}>
                <span className={style.Minute}>
                  {converSecTommss(diffTime).split(":")[0]}
                </span>
                <span className={style.MinuteText}>分</span>
                <span className={style.second}>
                  {converSecTommss(diffTime).split(":")[1]}
                </span>
                <span className={style.secondText}>秒</span>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* 正在开奖中显示loading */}
          {diffTime == 0 ? (
            <div className={style.showLoading}>
              <span className={style.text}>正在开奖</span>
              <span className={style.loadingImg}></span>
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      {/* 最新一期开奖结果 */}
      <div className={style.newOpenResult}>
        <span className={style.ball}>{data&&handlerOpenResultNumber()[0]}</span>
        <span className={style.tag}>+</span>
        <span className={style.ball}>{data&&handlerOpenResultNumber()[1]}</span>
        <span className={style.tag}>+</span>
        <span className={style.ball}>{data&&handlerOpenResultNumber()[2]}</span>
        <span className={style.tag}>=</span>
        {/* 和 */}
        <span className={style.toatl}>{data&&Number(handlerOpenResultNumber()[3])}</span>
        {/* 小单 大双 小双 大单 */}
        <span className={style.size+' '+style[data&&Number(handlerOpenResultNumber()[3])>=14?"":"small"]}>{data&&Number(handlerOpenResultNumber()[3])>=14?"大":"小"}</span>
        <span className={style.size+' '+style[data&&Number(handlerOpenResultNumber()[3])%2?"small":""]}>{data&&Number(handlerOpenResultNumber()[3])%2?"单":"双"}</span>
      </div>

      {/* 咪牌模式 */}
      <div className={style.mipai}>
            <span className={style.button} onClick={()=>handlerClickMipai(true)}>咪牌模式</span>
      </div>

      {/* 咪牌容器 */}
      {showMipai&&
      <div className={style.mipaiContainer}>
        <div className={style.content} ref={canvasPEl}>
          {/* 期数信息 */}
          <div className={style.lotNumberDesc}>
             <div className={style.top}>
                第<span>{currentLotNumber}</span>期开奖结果
             </div>
             <div className={style.bottom}>
                  {diffTime ? (
                 <div className={style.countDownContent}>
                    <span className={style.Minute}>
                      {converSecTommss(diffTime).split(":")[0]}
                    </span>
                    <span className={style.MinuteText}>分</span>
                    <span className={style.second}>
                      {converSecTommss(diffTime).split(":")[1]}
                    </span>
                    <span className={style.secondText}>秒</span>
                </div>
                ) : (
                  ""
                )}

                {/* 正在开奖中显示loading */}
                {diffTime == 0 ?(
                  <div className={style.showLoading}>
                    <span className={style.text}>正在开奖{pointArr[pointNumber]}&nbsp;</span>
                  </div>
                ): (
                  ""
                )}
             </div>
          </div>
          {/* 刮刮乐区域 */}
          <div className={style.guaguale} >
              {data&&data.List[0].LotResultNo}
              <canvas id={style.cvs} ref={canvasEl}  width="300" height="105"></canvas>
          </div>
          {/* 提示等待开奖和已经开奖 */}
          <div className={style.btnGroup}>
             <span className={style.refreshBtn} onClick={()=>handlerRefrsh()}>刷新</span>
             <span className={style.closeBtn} onClick={()=>handlerClickMipai(false)}>关闭</span>
          </div>
        </div>
      </div>
       }
    </div>
  );
};

export default observer(Canada28Online);
