import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useMemo,
    Fragment,
} from "react";
import { observer } from 'mobx-react-lite'
import store from '../../stores'
import Canada28 from "./Result/canada28/canada28";
import Baijiale28 from "./Result/baijiale28/baijiale28";
import Niuniu28 from "./Result/niuniu28/niuniu28";
import Aozhou28 from "./Result/aozhou28/aozhou28";
import Xingyun10 from "./Result/xingyun10/xingyun10";
import Zoushi from "./zoushi/zoushi";
import Yuce from "./yuce/yuce";

import style from "./index.module.less";
import TronHash28 from "./Result/tronhash28/tronhash28";
import HashInstant from "./Result/hashinstant/hashinstant";
import Pk10Hash from "./Result/pk10hash/pk10hash";
import Baijiale28Online from "../Baijiale28Online";

const RecordContainer = () => {
    return (
        <div className={style.RecordContainer}>
            {/* <div className={style.buttonGroup}>
                <div className={style.result} onClick={()=>store.handlerTagClick(0)}>
                    <span className={style.resultIcon}></span>
                    结果
                    <span className={style.resultTag+' '+(store.currentTagIndex==0?style['active']:'')}></span>
                </div>
                <div className={style.cuishi} onClick={()=>store.handlerTagClick(1)}>
                    <span className={style.cuishiIcon}></span>
                    走势
                    <span className={style.cuishiTag+' '+(store.currentTagIndex==1&&store.currentIndex==0?style['active']:'')}></span>
                </div>
                <div className={style.yuce} onClick={()=>store.handlerTagClick(2)}>
                    <span className={style.yuceIcon}></span>
                    预测
                    <span className={style.yuceTag+' '+(store.currentTagIndex==2&&store.currentIndex==0?style['active']:'')}></span>
                </div>
           </div> */}

            {/* 数据展示 */}
            <div className={style.resultContainer}>
                {store.currentIndex == 0 && <Canada28></Canada28>}
                {/* store.currentIndex == 1 && <Aozhou28></Aozhou28> */}
                {store.currentIndex == 1 && <Baijiale28></Baijiale28>}
                {store.currentIndex == 2 && <Niuniu28></Niuniu28>}
                {store.currentIndex == 3 && <HashInstant></HashInstant>}
                {/* store.currentIndex == 4 && <Xingyun10></Xingyun10> */}
                {/* store.currentIndex == 5 && <Pk10Hash></Pk10Hash> */}
                {/* {store.currentTagIndex==1&&store.currentIndex==0&&<Zoushi></Zoushi>} */}
                {/* {store.currentTagIndex==2&&store.currentIndex==0&&<Yuce></Yuce>} */}
            </div>
        </div>
    )
}
export default observer(RecordContainer);