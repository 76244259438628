import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useMemo,
  Fragment,
} from "react";
import { observer } from 'mobx-react-lite'
import store from '../../stores'
import moment from "moment";
import { GetLotLuckyOpenResultList } from "../../api";
import style from "./index.module.less";

const Xingyun10Online = () => {
  const [data, setData] = useState(null);
  const [resData, setResData] = useState(null);
  const [diffTime, setDiffTime] = useState(0); // 时间差 /倒计时时间
  const [currentLotNumber, setCurrentLotNumber] = useState(0); //当前最新期号
  const [oddLotNumber, setOddLotNumber] = useState(0); //当前最旧期号
  const [currentSelectLotNumber, setCurrentSelectLotNumber] = useState(0); //当前选中期号
  const timerRef = useRef(null);
  const onetimerRef = useRef(null);
  const clicktimerRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  //获取记录和下期开奖日期
  const fetchData = async () => {
    Promise.all([GetLotLuckyOpenResultList()]).then(res => {
      if (res) {
        store.setLotteryDataList(res, 4);
        setResData(res);

        setData(res[0].Data);

        //计算下一期剩余时间和最新期号
        const {
          NextDrawInfo: { CurrentTime, NextDrawTime },
          List,
        } = res[0].Data;
        setCurrentLotNumber(List[0].LotNumber);         // 当前期数
        setOddLotNumber(List[List.length - 1].LotNumber);             // 上期期数
        setCurrentSelectLotNumber(List[0].LotNumber);   // 当前选中的期号
        let nextTime = CurrentTime.split(" ")[0] + " " + NextDrawTime;
        let diffTimes = moment(nextTime).diff(moment(CurrentTime), "s");
        setDiffTime(diffTimes);
      }
    }).catch(err => {
      clearTimeout(onetimerRef.current);
      if (store.currentIndex !== 2) {
        return;
      }
      onetimerRef.current = setTimeout(() => {
        fetchData();
      }, 6000);
    })
  };

  useEffect(() => {
    // 开启定时器
    if (diffTime <= 0) {
      window.clearInterval(timerRef.current);
      window.clearInterval(onetimerRef.current);
      setDiffTime(0);
      onetimerRef.current = null;

      clearTimeout(onetimerRef.current);
      onetimerRef.current = setTimeout(() => {
        fetchData();
      }, 6000);
      return;
    } else {
      timerRef.current = window.setInterval(() => {
        if (diffTime >= 1) {
          setDiffTime((diffTime) => diffTime - 1);
        }
      }, 1000);
    }
    return () => {
      clearInterval(timerRef.current);
      clearInterval(onetimerRef.current);
    };
  }, [diffTime]);

  useEffect(() => {
    // 清除副作用
    return () => {
      clearInterval(timerRef.current);
      clearTimeout(onetimerRef.current);
      clearTimeout(clicktimerRef.current);
    };
  }, []);

  //将秒数转换为分秒
  const converSecTommss = (diffTime) => {
    let currentSec = moment.duration(diffTime, "seconds");
    return moment({
      m: currentSec.minutes(),
      s: currentSec.seconds(),
    }).format("mm:ss");
  };

  //add
  const HandlingAddOrSubtraction = (type) => {
    clearTimeout(clicktimerRef.current);
    clicktimerRef.current = setTimeout(() => {
      if (type == "add") {
        currentSelectLotNumber < currentLotNumber &&
          setCurrentSelectLotNumber((pre) => pre + 1);
      } else {
        currentSelectLotNumber > oddLotNumber &&
          setCurrentSelectLotNumber((pre) => pre - 1);
      }
    }, 300);
  };

  //处理是否禁用
  const handlerIsDisabled = (type) => {
    if (type == "add") {
      return currentSelectLotNumber < currentLotNumber &&
        currentSelectLotNumber >= oddLotNumber
        ? false
        : true;
    } else {
      return currentSelectLotNumber <= currentLotNumber &&
        currentSelectLotNumber > oddLotNumber
        ? false
        : true;
    }
  };

  // 处理开奖号码
  const ResultFormatt = () => {
    let obj = data ? data.List.filter(v => v.LotNumber == currentSelectLotNumber) : [];
    let arr = obj[0] ? obj[0].LotResultNo.split(',') : [];
    return TypeToName(arr, store.xingyunShowType);
  };

  const TypeToName = (arr, type) => {
    let narr = []
    if (type == 1) {
      narr = arr
    } else if (type == 2) {
      narr = arr.map(v => { return Number(v) > 5 ? '大' : '小' })
    } else if (type == 3) {
      narr = arr.map(v => { return v % 2 == 0 ? '双' : '单' })
    }
    return narr
  }

  const TypeToClass = (v) => {
    let nClass = ''
    if(['小','单'].includes(v)){
      nClass = 'bg7f8ab0'
    }
    if(['大','双'].includes(v)){
      nClass = 'bgf9982e'
    }
    return nClass
  }

  const ChangeShowType = (type) => {
    store.changeXYShowType(type)
  }

  return (
    <div className={style.canada28Online}>
      {/* 最新期数 */}
      <div className={style.latestIssue}>
        {/* 图标 */}
        {/* <span className={style.img}></span> */}
        {/* 期数内容 */}
        <div className={style.content}>
          <div className={style.LotNumber}>
            最新：
            <span className={style.number}>
              {data && data.List[0].LotNumber}
            </span>
            期
          </div>
          <div className={style.PhaseController}>
            <button
              className={style.reduce}
              disabled={handlerIsDisabled("reducer")}
              onClick={() => HandlingAddOrSubtraction("reducer")}
            >
              -
            </button>
            <span>{currentSelectLotNumber}</span>
            <button
              className={style.add}
              disabled={handlerIsDisabled("add")}
              onClick={() => HandlingAddOrSubtraction("add")}
            >
              +
            </button>
          </div>
        </div>
      </div>

      {/* 开奖倒计时 */}
      {data && (
        <div className={style.countdown}>
          {/* 尚未开奖显示倒计时 */}
          {diffTime ? (
            <div className={style.ShowCountDown}>
              <span className={style.nextTetx}>下一期：</span>
              <div className={style.countDownContent}>
                <span className={style.Minute}>
                  {converSecTommss(diffTime).split(":")[0]}
                </span>
                <span className={style.MinuteText}>分</span>
                <span className={style.second}>
                  {converSecTommss(diffTime).split(":")[1]}
                </span>
                <span className={style.secondText}>秒</span>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* 正在开奖中显示loading */}
          {diffTime == 0 ? (
            <div className={style.showLoading}>
              <span className={style.text}>正在开奖</span>
              <span className={style.loadingImg}></span>
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      {/* 最新一期开奖结果 */}
      <div className={style.newOpenResult}>
        <div className={style.top}>
          <div className={style.item} onClick={() => ChangeShowType(1)}><span className={store.xingyunShowType == 1 ? style.active : ''}>显示号码</span></div>
          <div className={style.item} onClick={() => ChangeShowType(2)}><span className={store.xingyunShowType == 2 ? style.active : ''}>显示大小</span></div>
          <div className={style.item} onClick={() => ChangeShowType(3)}><span className={store.xingyunShowType == 3 ? style.active : ''}>显示单双</span></div>
        </div>
        <div className={style.num}>
          {ResultFormatt().map((item, index) => {
            return (
              <span className={[style['span' + item], style[TypeToClass(item)]].join(' ')} key={index}>{item}</span>
            )
          })}
        </div>
      </div>

    </div >
  );
};

export default observer(Xingyun10Online);
