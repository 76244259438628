import React, { memo, useState, useEffect, createContext } from "react";
import Header from "../../components/Header";
import SwiperBanner from "../../components/Swiper";
import HongKongMarkSix from "../../components/HongKongMarkSix";
import LotteryLayout from "../../components/LotteryLayout";

const Home = () => {
    return (
        <div>
            <Header></Header>
            <SwiperBanner></SwiperBanner>
            <HongKongMarkSix></HongKongMarkSix>
            <LotteryLayout></LotteryLayout>
        </div>
    )
}
export default Home