import './App.css';
import { BrowserRouter,HashRouter, Route, Routes } from "react-router-dom"
import AZPK10 from './pages/AZPK10'
import Home from './pages/Home'

function App() {
  return (
    //   <HashRouter>
    //     <div className="App">
    //         <Route path="/" element={<Home/>}>
    //         </Route>
    //         <Route path="/AZPK10" element={<AZPK10/>}/>
    //     </div>
    // </HashRouter>
    <div className="App">
      <Home/>
    </div>

  )
}

export default App;
