import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useMemo,
  Fragment,
} from "react";
import { observer } from 'mobx-react-lite'
import store from '../../stores'
import { GetXGCOpenResultList,GetCowsOpenResultList,GetBaccaratOpenResultList} from "../../api";
import style from "./index.module.less";
import { getZodiacList } from "./zodiac";

const HongKongMarkSix = () => {
  const [markSixData, setMarkSixData] = useState(null);
  const [showRecord, setShowRecord] = useState(false);
  const timerRef = useRef(null);
  const randomColor = ["#f66","brown","#f66", "#409dff", "crimson", "#e9ac3a","#41b883","#2265fa","#ff2b30","#be6919","burlywood"];

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.clearInterval(timerRef.current)
    timerRef.current = window.setInterval(() => {
        // console.log(markSixData)
        fetchData();
    }, 60*1000)
    return () =>{
        window.clearInterval(timerRef.current);
    } 
  }, [markSixData]);

  const fetchData = async () => {
    
    const res = await GetXGCOpenResultList();
    if (res && res.ErrorCode === 0 && res.Success) {
      setMarkSixData(res.Data);
    } else {
       fetchData();
    }
  };

  //格式化开奖结果
  const handlerBallFormatt = useMemo(() => {
    const List = (markSixData && markSixData.List) || null;
    let LotSXResult = null,
      LotWuXingResult = null,
      LotResultNo = null;
    if (List != null) {
      /*
      LotSXResult = List && List[0].LotSXResult.split("|");
      LotWuXingResult = List && List[0].LotWuXingResult.split("|");
      LotResultNo = List && List[0].LotResultNo.split("|");
       */
      /*
      if (List[0].hasOwnProperty("LotSXResult") && List[0].LotSXResult != null) {
        LotSXResult = List && List[0].LotSXResult.split("|");
      }
       */

      if (List[0].hasOwnProperty("LotWuXingResult") && List[0].LotWuXingResult != null) {
        LotWuXingResult = List && List[0].LotWuXingResult.split("|");
      }

      if (List[0].hasOwnProperty("LotResultNo") && List[0].LotResultNo != null) {
        LotSXResult = getZodiacList(List[0].LotResultNo);
        LotResultNo = List && List[0].LotResultNo.split("|");
      }
    }

    return {
      LotSXResult,
      LotWuXingResult,
      LotResultNo,
    };
  }, [markSixData]);

  //处理忘记记录列表数据
  const handlePreRecordData = useMemo(() => {
    const List = (markSixData && markSixData.List) || null;
    if (List) {
      let copyList = [...List];
      copyList.splice(0, 1);
      copyList.map((item, index) => {
        copyList[index] = {
          ...item,
          // LotSXResult: item.LotSXResult.split("|"),
          LotSXResult: getZodiacList(item.LotResultNo),
          LotWuXingResult: item.LotWuXingResult.split("|"),
          LotResultNo: item.LotResultNo.split("|"),
        };
      });
      return copyList;
    }
    return false;
  }, [markSixData]);

  //处理球的波色
  const handlerBallColor = (code) => {
    code = Number(code);
    //红球
    let redBall = [
      1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46,
    ];
    //篮球
    let blueBall = [
      3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48,
    ];
    //绿球
    let greenBall = [
      5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49,
    ];
    //判断是什么类型的球返回对应的class
    if (redBall.includes(code)) return "redBall";
    if (blueBall.includes(code)) return "blueBall";
    if (greenBall.includes(code)) return "greenBall";
  };

  //处理点击显示隐藏记录
  const handlerShowRecord = () => {
    setShowRecord(showRecord=>!showRecord);
  };

  return (
    <div className={style.hongkongSix}>
      <div className={style.hongkongSixHeaderTitleBox}>
        {/* 最新一期六合彩 */}
        <div className={style.newMarkSix}>
          {markSixData && markSixData.List[0].LotResultTime} 港彩
          <span> {markSixData && markSixData.List[0].LotNumber}</span>期
        </div>

        {/* 最新一期六合彩开奖结果 */}
        <div className={style.newMarkSixOpenResult}>
          <ul className={style.ulBox}>
            {handlerBallFormatt.LotResultNo &&
              handlerBallFormatt.LotResultNo.map((item, index) =>
                index === 6 ? (
                  <Fragment key={index}>
                    <li>
                      <span style={{fontWeight:400,fontSize:'30px'}}>+</span>
                    </li>
                    <li>
                      <span
                        className={
                          style.openBall + " " + style[handlerBallColor(item)]
                        }
                      >
                        {item}
                      </span>
                      <span className={style.Attributes}>
                        <i>{handlerBallFormatt.LotSXResult[index]}</i>
                        {/* <i style={{color:randomColor[Math.ceil(Math.random()*10)]}}>{handlerBallFormatt.LotWuXingResult[index]}</i> */}
                      </span>
                    </li>
                  </Fragment>
                ) : (
                  <li key={index}>
                    <span
                      className={
                        style.openBall + " " + style[handlerBallColor(item)]
                      }
                    >
                      {item}
                    </span>
                    <span className={style.Attributes}>
                      <i>{handlerBallFormatt.LotSXResult[index]}</i>
                      {/* <i style={{color:randomColor[Math.ceil(Math.random()*10)]}}>{handlerBallFormatt.LotWuXingResult[index]}</i> */}
                    </span>
                  </li>
                )
              )}
          </ul>
        </div>

        {/* 下一期开奖时间 */}
        <div className={style.nextOpenResultTime} onClick={handlerShowRecord}>
          第 {markSixData && markSixData.NeedOpenInfo.NeedLotNumber}{" "}
          期开奖时间：00天00小时00分00秒
        </div>

        {/* 下一期开奖时间 */}
        <div
          className={style.handlerNextOpenResultBtn+' '+style[showRecord?'borderBot':'']}
          onClick={handlerShowRecord}
        >
          香港六合彩往期走势
        </div>

        {/* 往期记录 */}
        <div className={style.preRecordParent}>
          {showRecord &&
            handlePreRecordData &&
            handlePreRecordData.map((item, index) => {
              return (
                <Fragment key={index}>
                  <div
                    className={
                      style.newMarkSix +
                      " " +
                      ((index + 1) % 2 === 0
                        ? style["even"]
                        : style["oddNumber"])
                    }
                  >
                    {item.LotResultTime} 港彩
                    <span> {item.LotNumber}</span>期
                  </div>
                  <div
                    className={
                      style.newMarkSixOpenResult +
                      " " +
                      ((index + 1) % 2 === 0
                        ? style["even"]
                        : style["oddNumber"])
                    }
                    key={index}
                  >
                    <ul className={style.ulBox}>
                      {item.LotResultNo &&
                        item.LotResultNo.map((item2, index2) =>
                          index2 === 6 ? (
                            <Fragment key={index2}>
                              <li>
                                <span style={{fontWeight:400,fontSize:'30px'}}>+</span>
                              </li>
                              <li>
                                <span
                                  className={
                                    style.openBall +
                                    " " +
                                    style[handlerBallColor(item2)]
                                  }
                                >
                                  {item2}
                                </span>
                                <span className={style.Attributes}>
                                  <i>{item.LotSXResult[index2]}</i>
                                  {/* <i style={{color:randomColor[Math.ceil(Math.random()*10)]}}>{item.LotWuXingResult[index2]}</i> */}
                                </span>
                              </li>
                            </Fragment>
                          ) : (
                            <li key={index2}>
                              <span
                                className={
                                  style.openBall +
                                  " " +
                                  style[handlerBallColor(item2)]
                                }
                              >
                                {item2}
                              </span>
                              <span className={style.Attributes}>
                                <i>{item.LotSXResult[index2]}</i>
                                {/* <i style={{color:randomColor[Math.ceil(Math.random()*10)]}}>{item.LotWuXingResult[index2]}</i> */}
                              </span>
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                </Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default observer(HongKongMarkSix);
