import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useMemo,
    Fragment,
  } from "react";
import { observer } from 'mobx-react-lite'
import store from "../../../stores";
import style from "./index.module.less";
const Zoushi = () => {
    const ResultFormatt = useMemo(() => {
        let object = {
            big: 0,
            s:0,
            d:0,
            s:0,
            dd:0,
            ds:0,
            xd:0,
            xs:0,
        }
        if(store.lotteryDataList&&store.lotteryDataList[0]) {
            let list = JSON.parse(JSON.stringify(store.lotteryDataList[0].Data.List));
            //大>13
            //小<14
            //单%2!==0
            //双%2==0
            //大单>13&&%2!==0
            //大双>13&&%2==0
            //小单<14%2!==0
            //小双<14%2==0
            list.map((item,index)=>{
                let arr = item.LotResultNo.replace("=","+").split('+')
                let nweArr = [...arr];
                let flag = {
                    da: Number(nweArr[nweArr.length-1])>13?true:false,
                    xiao: Number(nweArr[nweArr.length-1])<14?true:false,
                    dan: Number(nweArr[nweArr.length-1])%2==0?false:true,
                    shuang: Number(nweArr[nweArr.length-1])%2==0?true:false,
                    dadan: (Number(nweArr[nweArr.length-1])>13&&Number(nweArr[nweArr.length-1])%2>0)?true:false,
                    dashuang: (Number(nweArr[nweArr.length-1])>13&&Number(nweArr[nweArr.length-1])%2==0)?true:false,
                    xiaodan: (Number(nweArr[nweArr.length-1])<14&&Number(nweArr[nweArr.length-1])%2>0)?true:false,
                    xiaoshuang: (Number(nweArr[nweArr.length-1])<14&&Number(nweArr[nweArr.length-1])%2==0)?true:false,
                }
                item['indexs'] = index+1;
                item["flag"] = flag;
                item.LotResultNo = arr.join("|");
            })
            //距离上一期是多久
            let frequency = {
                da: list.find(item=>item.flag.da===true)['indexs']?list.find(item=>item.flag.da===true)['indexs']-1:list.length,
                xiao: list.find(item=>item.flag.xiao===true)['indexs']?list.find(item=>item.flag.xiao===true)['indexs']-1:list.length,
                dan: list.find(item=>item.flag.dan===true)['indexs']?list.find(item=>item.flag.dan===true)['indexs']-1:list.length,
                shuang:list.find(item=>item.flag.shuang===true)['indexs']?list.find(item=>item.flag.shuang===true)['indexs']-1:list.length,
                dadan: list.find(item=>item.flag.dadan===true)['indexs']?list.find(item=>item.flag.dadan===true)['indexs']-1:list.length,
                dashuang:list.find(item=>item.flag.dashuang===true)['indexs']?list.find(item=>item.flag.dashuang===true)['indexs']-1:list.length,
                xiaodan:list.find(item=>item.flag.xiaodan===true)['indexs']?list.find(item=>item.flag.xiaodan===true)['indexs']-1:list.length,
                xiaoshuang:list.find(item=>item.flag.xiaoshuang===true)['indexs']?list.find(item=>item.flag.xiaoshuang===true)['indexs']-1:list.length
            }
            console.log(list)
            return {
                list,
                frequency
            }
        }
        return false;
    }, [store.lotteryDataList]);
    return(
        <div className={style.container}>
            <table className={style.tabel} cellSpacing="0">
                <tr>
                    <td>期号</td>
                    <td>和</td>
                    <td>大</td>
                    <td>小</td>
                    <td>单</td>
                    <td>双</td>
                    <td>大单</td>
                    <td>大双</td>
                    <td>小单</td>
                    <td>小双</td>
                </tr>
                <tr>
                    <td>间隔</td>
                    <td>-</td>
                    <td>{ResultFormatt&&ResultFormatt.frequency.da}</td>
                    <td>{ResultFormatt&&ResultFormatt.frequency.xiao}</td>
                    <td>{ResultFormatt&&ResultFormatt.frequency.dan}</td>
                    <td>{ResultFormatt&&ResultFormatt.frequency.shuang}</td>
                    <td>{ResultFormatt&&ResultFormatt.frequency.dadan}</td>
                    <td>{ResultFormatt&&ResultFormatt.frequency.dashuang}</td>
                    <td>{ResultFormatt&&ResultFormatt.frequency.xiaodan}</td>
                    <td>{ResultFormatt&&ResultFormatt.frequency.xiaoshuang}</td>
                </tr>
                {ResultFormatt&&ResultFormatt.list.map(item=>{
                    return (
                        <tr>
                            <td>{item.LotNumber}</td>
                            <td>{item.LotResultNo.split('|')[item.LotResultNo.split('|').length-1]}</td>
                            <td>{item.flag.da&&<span className={style.da}>大</span>}</td>
                            <td>{item.flag.xiao&&<span className={style.xiao}>小</span>}</td>
                            <td>{item.flag.dan&&<span className={style.dan}>单</span>}</td>
                            <td>{item.flag.shuang&&<span className={style.shuang}>双</span>}</td>
                            <td>{item.flag.dadan&&<span className={style.dadan}>大单</span>}</td>
                            <td>{item.flag.dashuang&&<span className={style.dashuang}>大双</span>}</td>
                            <td>{item.flag.xiaodan&&<span className={style.xiaodan}>小单</span>}</td>
                            <td>{item.flag.xiaoshuang&&<span className={style.xiaoshuang}>小双</span>}</td>
                        </tr>
                    )
                })}
                
            </table>
        </div>
    )
}
export default observer(Zoushi);