import style from "./index.module.less";
const Header = () => {
    return (
        <div className={style.logoHeader}>
            <div className={style.content}>
                <div className={style.logo}></div>
            </div>
        </div> 
    )
}

export default Header