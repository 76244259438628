import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useMemo,
    Fragment,
  } from "react";
import { observer } from 'mobx-react-lite'
import store from "../../../../stores";
import style from "./index.module.less";
const Baijiale28 = () => {
    const ResultFormatt = useMemo(() => {
        if(store.baijialeData&&store.baijialeData.length>1) {
           return store.baijialeData[1].Data.List;
        }
        return false;
    },[store.baijialeData]);

    const handlerFormat = (item,index) => {
        if(item) {
            let obj = Object.assign({},item);
            return obj.LotResultNo[index];
        } else {
            return ''
        }
    }

    const handlerColor = (content) => {
        if(content=="和") return "#19b2b0";
        if(content=="闲对") return "#2862ff";
        if(content=="庄对") return "#ff1e1e";
        if(content=="庄赢") return "#ff1e1e";
        if(content=="闲赢") return "#2862ff";
    }

    const handlerResultNoColor = (content) => {
        const noArray = content.split('').map(Number);
        if (noArray.length === 3) {
            let res = '';
            const first = noArray[0];
            const last = noArray[2];
            if (first > last) {
                res = '庄赢';
            } else if (first == last) {
                res = '和';
            } else {
                res = '闲赢';
            }
            return handlerColor(res);
        }
        return '';
    }

    const handlerResultNo = (content) => {
        const noArray = content.split('').map(Number);
        if (noArray.length === 3) {
            let res = '';
            const first = noArray[0];
            const last = noArray[2];
            if (first > last) {
                res = '庄赢';
            } else if (first == last) {
                res = '和';
            } else {
                res = '闲赢';
            }
            return res;
        }
        return '';
    }

    const handlerBgColor = (item,index) => {
        let arrColor = ["","#ff1e1e","#2862ff"];
        let NoNumber = item.LotResultNo;
        if(index==0) {
            if(item.Pretend&&item.Pretend=="和") return '#19b2b0';
            if(item.Pretend&&item.Pretend=="闲对"&&NoNumber[0]==NoNumber[1]) return '#19b2b0';
            if(item.Pretend&&item.Pretend=="庄对"&&NoNumber[0]==NoNumber[1]) return '#19b2b0';
            //生成随机数
            return arrColor[Math.ceil(Math.random()*2)];
        }
        if(index==1) {
            if(item.Pretend&&item.Pretend=="和") return "#2862ff";
            if(item.Pretend&&item.Pretend=="闲对"&&NoNumber[0]==NoNumber[1]||NoNumber[1]==NoNumber[2]) return "#19b2b0";
            if(item.Pretend&&item.Pretend=="庄对"&&NoNumber[0]==NoNumber[1]||NoNumber[1]==NoNumber[2]) return "#19b2b0";
            //生成随机数
            return arrColor[Math.ceil(Math.random()*2)];
        }
        if(index==2) {
            let NoNumber = item.LotResultNo;
            if(item.Pretend&&item.Pretend=="和") return '#19b2b0';
            if(item.Pretend&&item.Pretend=="闲对"&&NoNumber[1]==NoNumber[2]) return '#19b2b0';
            if(item.Pretend&&item.Pretend=="庄对"&&NoNumber[1]==NoNumber[2]) return '#19b2b0';
            //生成随机数
            return arrColor[Math.ceil(Math.random()*2)];
        }
    }
    return(
        <div className={style.container}>
            <table className={style.tabel} cellSpacing="0">
                <tbody>
                <tr>
                    <td>期号 </td>
                    <td>号码</td>
                    <td>庄闲对</td>
                    <td>结果</td>
                </tr>
                {ResultFormatt&&ResultFormatt.length&&ResultFormatt.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.LotNumber}</td>
                            <td>
                                <span className={style.Ball} style={{backgroundColor:handlerBgColor(item,0)}}>{handlerFormat(item,0)}</span>
                                <span className={style.Ball} style={{backgroundColor:handlerBgColor(item,1)}}>{handlerFormat(item,1)}</span>
                                <span className={style.Ball} style={{backgroundColor:handlerBgColor(item,2)}}>{handlerFormat(item,2)}</span>
                            </td>
                            <td>
                                {item.Pretend&&<span style={{color: handlerColor(item.Pretend)}}>{item.Pretend}</span>}
                            </td>
                            <td>
                                <span style={{color: handlerResultNoColor(item.LotResultNo)}}>{handlerResultNo(item.LotResultNo)}</span>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
export default observer(Baijiale28);