import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useMemo,
    Fragment,
  } from "react";
import { observer } from 'mobx-react-lite'
import store from "../../../../stores";
import style from "./index.module.less";
const Aozhou28 = () => {
    const ResultFormatt = useMemo(() => {
        if(store.aozhouData) {
           return store.aozhouData.Data.List;
        }
        return false;
    },[store.aozhouData]);
    return(
        <div className={style.container}>
            <table className={style.tabel} cellSpacing="0">
                <tbody>
                <tr>
                    <td>期号 </td>
                    <td>时间</td>
                    <td>号码</td>
                </tr>
                {ResultFormatt&&ResultFormatt.length&&ResultFormatt.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.LotNumber}</td>
                            <td>{item.LotResultTime}</td>
                            <td>{item.LotResultNo}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
export default observer(Aozhou28);