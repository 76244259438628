import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useMemo,
  Fragment,
} from "react";
import { observer } from 'mobx-react-lite'
import store from "../../stores";
import TypeButton from "../../components/TypeButton";
import Canada28Online from "../../components/Canada28Online";
import Aozhou28Online from "../../components/Aozhou28Online";
import Niuniu28Online from "../../components/Niuniu28Online";
import Baijiale28Online from "../../components/Baijiale28Online";
import Xingyun10Online from "../../components/Xingyun10Online";
import LotteryRecord from "../../components/LotteryRecord";
import TronHash28 from "../../components/TronHash28";
import Pk10Hash from "../../components/Pk10Hash";
import HashInstant from "../../components/HashInstant";
import style from "./index.module.less";

const LotteryLayout = () => {
  return (
    <div className={style.layout}>
      <TypeButton></TypeButton>
      {/* store.currentIndex == 0 && <Canada28Online></Canada28Online> */}
      {/* store.currentIndex == 1 && <Aozhou28Online></Aozhou28Online> */}
      {/* store.currentIndex == 2 && <Niuniu28Online></Niuniu28Online> */}
      {/* store.currentIndex == 3 && <HashInstant></HashInstant> */}
      {/* store.currentIndex == 4 && <Xingyun10Online></Xingyun10Online> */}
      {/* store.currentIndex == 5 && <Pk10Hash></Pk10Hash> */}
      {store.currentIndex == 0 && <Canada28Online></Canada28Online>}
      {store.currentIndex == 1 && <Baijiale28Online></Baijiale28Online>}
      {store.currentIndex == 2 && <Niuniu28Online></Niuniu28Online>}
      {store.currentIndex == 3 && <HashInstant></HashInstant>}
      <LotteryRecord></LotteryRecord>
    </div>
  )
}
export default observer(LotteryLayout)